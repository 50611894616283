
/***
 * GERAL
 ***/
$(function() {
    $('.animsition').animsition().one('animsition.inStart',function(){
        App.SetToggle();
        App.SetGaleria();
        App.SetBanner();
        App.SetCarouselMobile();
        App.SetHeaderFixed(80);
        App.SetValidate();
        App.SetMaskInput();
        App.SetInputError();
        App.SetFormSuccess();
        App.SetAnimatescroll(118);
        App.SetWow(200);

        $('html').addClass('loaded');

        // Popup curriculo
        $('[data-component=popup-curriculo]').magnificPopup({
            type: 'inline'
        });

        // Contador
        $('._contador').each(function(index, el) {
            var a = 0;

            $(window).scroll(function() {
                var oTop = $('._contador').offset().top - window.innerHeight;

                if (a == 0 && $(window).scrollTop() > oTop) {
                    $('.valor').each(function() {
                        var $this = $(this),
                            countTo = $this.attr('data-count');
                        $({
                            countNum: $this.text()
                        }).animate({
                            countNum: countTo
                        },
                        {
                            duration: 2000,
                            easing: 'swing',
                            step: function() {
                                $this.text(Math.floor(this.countNum));
                            },
                            complete: function() {
                                $this.text(this.countNum);
                            }
                        });
                    });
                    a = 1;
                }
            });
        });
    });
});


